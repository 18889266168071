import React, { useState } from 'react';
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";
import Select from "../components/Select";
import Code from "../components/Code";
import Applyform from "../components/Applyform";

function Uparking() {
  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || { name: 'N/A', phone: 'N/A', email: 'N/A', role: 'N/A', flat: 'N/A', tower: 'N/A' };

  const [formData, setFormData] = useState({
    parkingNumber: '',
    vehicleNo: '',
    vehicleModel: '',
    drivingLicenceNumber: '',
    drivingLicenceAttachment: null,
    remark: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'file' ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    data.append('role', userDetails.role);
    data.append('name', userDetails.name);
    data.append('phone', userDetails.phone);
    data.append('email', userDetails.email);
    data.append('flat', userDetails.flat);
    data.append('tower', userDetails.tower);
    data.append('parkingNumber', formData.parkingNumber);
    data.append('vehicleNo', formData.vehicleNo);
    data.append('vehicleModel', formData.vehicleModel);
    data.append('drivingLicenceNumber', formData.drivingLicenceNumber);
    data.append('drivingLicenceAttachment', formData.drivingLicenceAttachment);
    data.append('remark', formData.remark);

    try {
      const response = await fetch('https://amrapali-zodiac.in/admin-panel/api/parkings', {
        method: 'POST',
        body: data,
      });
      if (!response.ok) throw new Error('Network response was not ok.');
      setSuccess('Form submitted successfully!');
      setError(null);
    } catch (err) {
      setError(err.message);
      setSuccess(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Unavbar />
      <div className="row">
        <div className="col 12 p-5 bg-dark"> </div>
      </div>
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
          <Applyform />
          <div className="row mt-3 me-2 ms-1">
            <div className="col-12 card p-3 mb-4">
              <div className="row">
                <div className="col-12 mb-2">
                  <h5 className="radiant mt-2">Apply Parking Sticker</h5>
                 <hr />
                </div>
                <div className="row">
                    <div className="col-lg-4 col-12">
                      <label className="mt-1 mb-2">
                        <b> Do you have an old Parking Sticker ?</b> <span className="text-danger">*</span>
                      </label>
                    </div>
                    <Select />
                  </div>

              
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label className="mt-2"><b>I am an/a</b></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input type="text" className="w-100 form-control text-uppercase" value={userDetails.role} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="name"><b>Name</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="w-100 form-control text-uppercase" type="text" name="name" value={userDetails.name} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="phone"><b>Phone</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <div className="row">
                          <div className="col-4">
                            <Code />
                          </div>
                          <div className="col-8">
                            <input className="w-100 form-control text-uppercase" type="text" name="phone" value={userDetails.phone} disabled />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="email"><b>E-Mail ID:</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="form-control text-uppercase" type="text" id="email" name="email" placeholder="Enter Email" value={userDetails.email} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-3">
                        <label htmlFor="flat"><b>Flat Number</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="form-control text-uppercase" type="text" id="flat" name="flat" placeholder="Enter Flat" value={userDetails.flat} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="tower"><b>Tower</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="form-control text-uppercase" type="text" id="tower" name="tower" value={userDetails.tower} onChange={handleChange} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="parkingNumber"><b>Parking Number</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="form-control" type="text" id="parkingNumber" name="parkingNumber" placeholder="Enter Parking Number" value={formData.parkingNumber} onChange={handleChange} required />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="account-fn"> <b>Vehicle Type</b> <span className="text-danger">*</span> </label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                      <select name="VehicleType" className='form-select'   >
                       <option value="">Select Vehicle Type</option>
                       <option value="2 Wheeler">2 Wheeler</option>
                      <option value="4 Wheeler">4 Wheeler</option>
                         </select>
       
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="vehicleNo"><b>Vehicle No</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="form-control" type="text" id="vehicleNo" name="vehicleNo" placeholder="Enter Vehicle No" value={formData.vehicleNo} onChange={handleChange} required />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="vehicleModel"><b>Vehicle Model</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="form-control" type="text" id="vehicleModel" name="vehicleModel" placeholder="Enter Vehicle Model" value={formData.vehicleModel} onChange={handleChange} required />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-6 col-12 mt-2">
                        <label htmlFor="drivingLicenceNumber"><b>Driving Licence Number</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-6 col-12 mt-2">
                        <input className="form-control" type="text" id="drivingLicenceNumber" name="drivingLicenceNumber" placeholder="Enter Driving Licence Number" value={formData.drivingLicenceNumber} onChange={handleChange} required />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-5 col-12 mt-2">
                        <label htmlFor="drivingLicenceAttachment"><b>Proof of Parking No.</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-7 col-12 mt-2">
                        <input className="form-control w-100" type="file" id="drivingLicenceAttachment" name="drivingLicenceAttachment" onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-6 col-12 mt-2">
                        <label htmlFor="drivingLicenceAttachment"><b>Proof of Driving Licence No.</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-6 col-12 mt-2">
                        <input className="form-control w-100" type="file" id="drivingLicenceAttachment" name="drivingLicenceAttachment" onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 mb-3 form-group">
                    <div className="row">
                      <div className="col-lg-12 col-12">
                        <label htmlFor="remark"><b>Remark:</b></label>
                      </div>
                      <div className="col-12 mt-1">
                        <textarea className="form-control" id="remark" name="remark" rows={3} value={formData.remark} onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-3 col-12">
                      <input className="bg-primary text-white form-control" type="submit" value="Submit" disabled={loading} />
                    </div>
                  </div>
                  {error && <div className="text-danger form-control text-center">{error}</div>}
                  {success && <div className="text-success form-control text-center">{success}</div>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Uparking;
