import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Cduties from "../Upages/Cduties";
import { Helmet } from 'react-helmet';

const Duties = () =>{

    return(
        <>
    <Helmet>
        <title>Roles and Responsibilities of Residents and Their Sponsors</title>
        <meta name="title" property="og:title" content="Roles and Responsibilities of Residents and Their Sponsors" />
        <meta name="description" content="he role of residents and sponsors is to: • Understand the behaviour expectations of the home and your responsibilities. • Conduct yourself according to those" />
        <meta property="og:description" content="he role of residents and sponsors is to: • Understand the behaviour expectations of the home and your responsibilities. • Conduct yourself according to those" />
      </Helmet>
     <Navbar /> 
      <Cduties />
      <Footer />
        
        </>
    );
}
export default Duties;