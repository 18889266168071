import React, { useState } from 'react';
import axios from 'axios';
import Unav from '../components/Unav';
import Footer from '../components/Footer';
import Unavbar from '../components/Unavbar';
import Select from '../components/Select';
import Code from '../components/Code';
import Applyform from '../components/Applyform';



function Ucard() {
  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || { name: 'N/A', phone: 'N/A', email: 'N/A', role: 'N/A', flat: 'N/A', tower: 'N/A' };
  const [remark, setRemark] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');
  
    try {
      await axios.post('https://amrapali-zodiac.in/admin-panel/api/access-card', {
        name: userDetails.name,
        phone: userDetails.phone,
        email: userDetails.email,
        role: userDetails.role,
        flat: userDetails.flat,
        tower: userDetails.tower,
        
        remark,
      });
  
      setSuccess('Form submitted successfully!');
      setRemark('');
    } catch (err) {
      setError(err.response?.data?.message || 'Submission failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Unavbar />
      <div className="row">
        <div className="col-12 p-5 bg-dark"> </div>
      </div>
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
         <Applyform />
          <div className="row mt-2 me-2 ms-1">
            <div className="col-12 card p-3 mb-4">
              <div className="row">
                <div className="col-12">
                  <h5 className="radiant">Apply Access Card</h5>
                  <hr />
                </div>
                <form onSubmit={handleSubmit}>        
                  <div className="row">
                    <div className="col-lg-4 col-12">
                      <label className="mt-1 mb-2">
                        <b>Do you have an Access Card ?</b> <span className="text-danger">*</span>
                      </label>
                    </div>
                    <Select />
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-2">
                          <label className="mt-2"><b>I am an/a</b> </label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <input type="text" className="w-100 form-control text-uppercase" value={userDetails.role} disabled />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-2">
                          <label htmlFor="account-fn"> <b>Name</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <input className="w-100 form-control text-uppercase" type="text" name="name" value={userDetails.name} disabled />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-2">
                          <label htmlFor="account-fn"> <b>Phone</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <div className="row">
                            <div className="col-4">
                              <Code />
                            </div>
                            <div className="col-8">
                              <input className="w-100 form-control text-uppercase" type="text" name="phone" value={userDetails.phone} disabled />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-2">
                          <label htmlFor="account-fn"> <b>E-Mail ID:</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <input className="form-control text-uppercase" type="text" id="account-fn" value={userDetails.email} disabled />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-3">
                          <label htmlFor="account-fn"> <b>Flat Number</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <input className="form-control text-uppercase" type="text" id="account-fn" value={userDetails.flat} disabled />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-2">
                          <label htmlFor="account-fn"> <b>Tower</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <input className="form-control text-uppercase" type="text" id="account-fn" value={userDetails.tower} disabled />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-3 mb-3 form-group">
                      <div className="row">
                        <div className="col-lg-12 col-12">
                          <label htmlFor="remark"><b>Remarks:</b></label>
                        </div>
                        <div className="col-lg-12 col-12 mt-2">
                          <textarea className="form-control" id="remark" name="remark" rows={3} placeholder="Enter Remarks" value={remark} onChange={(e) => setRemark(e.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-end">
                    <div className="col-lg-3 col-12 mb-3">
                      <button className="bg-primary text-white form-control text-uppercase me-5" type="submit" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}
                      </button>
                    </div>
                  </div>
                  {error && <div className="col-12 text-center mt-4 mb-3 text-danger form-control " >{error}</div>}
                  {success && <div className="col-12 text-center mt-4 mb-3 text-success form-control">{success}</div>}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Ucard;
