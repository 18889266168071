import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Csociety from "../Upages/Csociety";
import { Helmet } from 'react-helmet';

const Society = () =>{

    return(
        <>
         <Helmet>
        <title>Tenants Rules and Regulations in Housing Society</title>
        <meta name="title" property="og:title" content="Tenants Rules and Regulations in Housing Society" />
        <meta name="description" content="Explore the rules and regulations tenants must follow in a housing society. This guide provides insights to ensure a harmonious living environment." />
        <meta property="og:description" content="Explore the rules and regulations tenants must follow in a housing society. This guide provides insights to ensure a harmonious living environment." />
         </Helmet>
           <Navbar />
   
        <Csociety />    
          <Footer/>
        </>
    );
}
export default Society;