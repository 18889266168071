import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Csociety from "../Upages/Csociety";
import { Helmet } from 'react-helmet';
const Swimming = () =>{

    return(
        <>
         <Helmet>
        <title>Swimming Pool Rules And Regulations In Apartment </title>
        <meta name="title" property="og:title" content="Swimming Pool Rules And Regulations In Apartment " />
        <meta name="description" content="Guidelines for using shared amenities like the clubhouse, gym, swimming pool, and community hall are outlined in these rules. It " />
        <meta property="og:description" content="Guidelines for using shared amenities like the clubhouse, gym, swimming pool, and community hall are outlined in these rules. It " />
      </Helmet>
      <Navbar />
     <Csociety />
      <Footer/>
        
        </>
    );
}
export default Swimming;