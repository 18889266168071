import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Cgym from "../Upages/Cgym";
import { Helmet } from 'react-helmet';
const Gym = () =>{

    return(
        <>
         <Helmet>
        <title>Housing Society Rules and Regulations for resident</title>
        <meta name="title" property="og:title" content="Housing Society Rules and Regulations for resident" />
        <meta name="description" content="Society is providing such amenities and facilities to the members to take a great experience e.g. swimming pool, gardens, club house, gym" />
        <meta property="og:description" content="Society is providing such amenities and facilities to the members to take a great experience e.g. swimming pool, gardens, club house, gym" />
       </Helmet>
        <Navbar />
   
      
         <Cgym />
            <Footer/>
        </>
    );
}
export default Gym;