import React, { useState } from "react";
import axios from 'axios'; // Make sure axios is installed and imported
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";
import Code from "../components/Code";
import Applyfrom from "../components/Applyform";

function Uhall() {
  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || { name: 'N/A', phone: 'N/A', email: 'N/A', role: 'N/A', flat: 'N/A' };
  const [gstNumber, setGstNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [fromBookingDateTime, setFromBookingDateTime] = useState('');
  const [toBookingDateTime, setToBookingDateTime] = useState('');
  const [address, setAddress] = useState('');
  const [remarks, setRemarks] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      await axios.post('https://amrapali-zodiac.in/admin-panel/api/bookings', {
        name: userDetails.name,
        phone: userDetails.phone,
        email: userDetails.email,
        gstNumber,
        companyName,
        businessType,
        fromBookingDateTime,
        toBookingDateTime,
        address,
        remarks,
      });

      setSuccess('Form submitted successfully!');
      setGstNumber('');
      setCompanyName('');
      setBusinessType('');
      setFromBookingDateTime('');
      setToBookingDateTime('');
      setAddress('');
      setRemarks('');
    } catch (err) {
      setError(err.response?.data?.message || 'Submission failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Unavbar />
      <div className="row">
        <div className="col-12 p-5 bg-dark"> </div>
      </div>
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
          <Applyfrom />
          <div className="row mt-3 me-2 ms-1">
            <div className="col-12 card p-3 mb-4">
              <div className="row">
                <div className="col-12">
                  <h5 className="radiant mt-2">Book Resources & Common Place</h5>
                  <hr />
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-12 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="account-fn"> <b>Name</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="w-100 form-control text-uppercase" type="text" name="name" value={userDetails.name} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="account-fn"> <b>Phone</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <div className="row">
                          <div className="col-4">
                            <Code />
                          </div>
                          <div className="col-8">
                            <input className="w-100 form-control text-uppercase" type="text" name="phone" value={userDetails.phone} disabled />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-6 col-12 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="account-fn"> <b>E-Mail ID:</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="w-100 form-control text-uppercase" type="text" name="email" value={userDetails.email} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="gstNumber"> <b>GST Number</b> </label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input
                          className="form-control"
                          type="text"
                          id="gstNumber"
                          placeholder="Enter GST Number"
                          value={gstNumber}
                          onChange={(e) => setGstNumber(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-6 col-12 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="companyName"> <b>Company Name</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input
                          className="form-control"
                          type="text"
                          id="companyName"
                          placeholder="Enter Company Name"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="businessType"> <b>Business Type</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input
                          className="form-control"
                          type="text"
                          id="businessType"
                          placeholder="Enter Business Type"
                          value={businessType}
                          onChange={(e) => setBusinessType(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-6 col-12 form-group">
                    <div className="row">
                      <div className="col-lg-12 col-12 mt-2">
                        <label htmlFor="fromBookingDateTime"> <b>Number Of Booking Days</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-6 col-12 mt-2">
                        <label htmlFor="toBookingDateTime">To:
                          <input
                            className="form-control"
                            type="datetime-local"
                            id="toBookingDateTime"
                            value={toBookingDateTime}
                            onChange={(e) => setToBookingDateTime(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                      <div className="col-lg-6 col-12 mt-2">
                        <label htmlFor="fromBookingDateTime">From:
                          <input
                            className="form-control"
                            type="datetime-local"
                            id="fromBookingDateTime"
                            value={fromBookingDateTime}
                            onChange={(e) => setFromBookingDateTime(e.target.value)}
                            required
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 form-group">
                    <label htmlFor="address"> <b>Address:</b> <span className="text-danger">*</span></label>
                    <div className="row">
                      <div className="col-12 mt-2">
                        <textarea
                          className="form-control"
                          id="address"
                          name="address"
                          rows={3}
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 mb-3">
                  <div className="col-lg-6 col-12">
                    <label htmlFor="remarks"> <b>Remarks:</b> </label>
                    <div className="row">
                      <div className="col-12 mt-3">
                        <textarea
                          className="form-control"
                          id="remarks"
                          name="remarks"
                          rows={3}
                          value={remarks}
                          onChange={(e) => setRemarks(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-end">
                  <div className="col-lg-3 col-12 mb-3">
                    <input
                      className="bg-primary text-white form-control text-uppercase"
                      type="submit"
                      value="Submit"
                      disabled={loading}
                    />
                  </div>
                </div>
                {error && <div className="text-danger form-control  text-center">{error}</div>}
                {success && <div className="text-success form-control  text-center">{success}</div>}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Uhall;
