import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Celection from "../Upages/Celection";
import { Helmet } from 'react-helmet';
const Election = () =>{

    return(
        <>
         <Helmet>
        <title>Housing Society Election : Process, Guide, Rules</title>
        <meta name="title" property="og:title" content="Housing Society Election : Process, Guide, Rules" />
        <meta name="description" content="The procedure for election of cooperative housing societies in Maharashtra is governed by the Maharashtra Cooperative Societies Act, 1960" />
        <meta property="og:description" content="The procedure for election of cooperative housing societies in Maharashtra is governed by the Maharashtra Cooperative Societies Act, 1960" />
       </Helmet>
        <Navbar />

       <Celection />
 
        <Footer/>
        </>
    );
}
export default Election;