import React from "react"
import { Link } from "react-router-dom";

const Applyform =() =>{

    return(
        <div className="row" id="applyform">
        <div className="col-lg-3 col-6 bg-primary" id="alink">
          <Link to="/rfid-tag" className="text-white">RFID TAG [Boom Barrier]</Link>
        </div>
        <div className="col-lg-3 col-6 bg-warning" id="alink">
          <Link to="/booking-society-area" className="text-white">BOOKING SOCIETY AREA</Link>
        </div>
        <div className="col-lg-2 col-4 bg-info" id="alink">
          <Link to="/pet-register" className="text-white">REGISTER PET'S</Link>
        </div>
        <div className="col-lg-2 col-4 bg-success" id="alink">
          <Link to="/access-card" className="text-white">ACCESS CARD</Link>
        </div>
        <div className="col-lg-2 col-4 bg-danger" id="alink">
          <Link to="/parking-sticker" className="text-white">PARKING STICKER</Link>
        </div>
      </div>
    )
}
export default Applyform;