import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Cparking from "../Upages/Cparking";
import { Helmet } from 'react-helmet';

const Parking = () => {

    return (
        <>
         <Helmet>
        <title>Reserve Car Parking Rules in Housing Societies</title>
        <meta name="title" property="og:title" content="Reserve Car Parking Rules in Housing Societies" />
        <meta name="description" content="Parking spaces could be reserved for people who park almost daily, and they could have a token system. RESIDENTIAL PARKING. Parking is only permitted in the" />
        <meta property="og:description" content="Parking spaces could be reserved for people who park almost daily, and they could have a token system. RESIDENTIAL PARKING. Parking is only permitted in the" />
       </Helmet>
          <Navbar />
         <Cparking/>
         
         <Footer/>

        </>
    );
}
export default Parking;