import React from 'react';
import { Link } from 'react-router-dom';



const Unav = () => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails')) || { name: 'N/A', tower: 'N/A', role: 'N/A', flat: 'N/A' };
    return (

        <>

            <div className="col-lg-3 bg-dark text-white ">
                <div className="row text-white" >
                    <div className="col-4 ms-2 text-center">
                        <img src="../assets/images/profile.png" alt="User-photo" className='w-75 rounded-circle' />
                    </div>
                    <div className="col-7">
                        <div className="row text-center ">
                            <div className="col-6 text-uppercase">{userDetails.name}</div>
                            <div className="col-6 text-uppercase"> {userDetails.role}</div>
                            <div className="col-6 text-uppercase mt-3">{userDetails.tower}</div>
                            <div className="col-6 text-uppercase mt-3">{userDetails.flat}</div>

                        </div>

                    </div>

                </div> <hr />

                <div className="col-12 ms-3">
                    <Link to="/myprofile" className="list-group-item ms-2 " >

                        <i class="bi bi-person"></i> &nbsp; &nbsp; My Account
                    </Link> <hr />

                    <nav className="ms-2">
                        <strong>
                            <Link to="/dashboard" className="text-white">
                                <i class="bi bi-house-door"></i>   &nbsp; &nbsp;  Dashboard
                            </Link>
                        </strong>   <hr />

                      
                        <li class="list-group-item " data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                            <i class="bi bi-bell"></i> &nbsp; &nbsp; Report List <span style={{marginLeft:'52%'}}> <b>+</b></span>
                        </li>
                        <div class="collapse" id="collapseExample">
                            <div class="card card-body me-3 ms-0 text-dark">
                            <Link to="/inbox" className="list-group-item" >
                            <i class="bi bi-bell"></i> &nbsp; &nbsp; Inbox 
                        </Link>  <hr />
                            <Link to="/communication-update" className="list-group-item" >
                            <i class="bi bi-journals"></i> &nbsp; &nbsp;
                            Communication Update
                        </Link> <hr />
                        <Link to="/event" className="list-group-item" >
                            <i class="bi bi-journals"></i> &nbsp; &nbsp;
                            Society Events
                        </Link>  <hr />
                        <Link to="/rules" className="list-group-item" >
                            <i class="bi bi-journals"></i> &nbsp; &nbsp;
                            Society Rules & Regulations  <br />  &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; For Residents
                        </Link>  <hr />
                            </div>
                        </div>
                      <hr />

                      <li class="list-group-item " data-bs-toggle="collapse" href="#collapseExample2" role="button" aria-expanded="false" aria-controls="collapseExample">
                      <i class="bi bi-bookmark-plus"></i> &nbsp; &nbsp; Approvals <span style={{marginLeft:'54%'}}> <b>+</b></span>
                        </li>
                        <div class="collapse mt-2" id="collapseExample2">
                            <div class="card me-3 ms-0 ">
                            
                            <Link to="/access-card" className="list-group-item bg-success p-3 text-white" >
                            <i class="bi bi-credit-card-2-back"></i>  &nbsp; &nbsp;
                            Apply Access card
                        </Link>

                        <Link to="/rfid-tag" className="list-group-item bg-primary p-3  text-white" >
                            <i class="bi bi-tags"></i>  &nbsp; &nbsp;
                            Apply RFID TAG [Boom Barrier]
                        </Link>  
                        <Link to="/parking-sticker" className="list-group-item bg-danger p-3  text-white" >
                            <i class="bi bi-p-circle"></i> &nbsp; &nbsp;
                            Apply Parking Sticker
                        </Link> 
                        <Link to="/pet-register" className="list-group-item bg-info p-3 text-white" >
                            <i class="bi bi-file-earmark-plus"></i> &nbsp; &nbsp;
                            Register Your Pet
                        </Link> 
                        <Link to="/booking-society-area" className="list-group-item bg-warning p-2 text-white" >
                            <i class="bi bi-file-earmark-plus"></i>  &nbsp; &nbsp;
                            Book Resources & Common <br />
                            &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; Place  e.g  Banquet hall... etc.
                        </Link> 
                            </div>
                        </div> <hr />
                      
                        <Link to="/staff" className="list-group-item" >
                            <i class="bi bi-person-workspace"></i>  &nbsp; &nbsp;
                            Daily Staff Report
                        </Link>  <hr />
                        <Link to="/voting" className="list-group-item" >
                            <i class="bi bi-badge-vo"></i>  &nbsp; &nbsp;
                            Online Voting | Make a Poll in Minutes
                        </Link>  <hr />
                        <Link to="/exit" className="list-group-item" >
                        <i class="bi bi-box-arrow-right"></i> &nbsp; &nbsp;
                           Exit Management 
                        </Link>  <hr />

                        <li class="list-group-item " data-bs-toggle="collapse" href="#collapseExample3" role="button" aria-expanded="false" aria-controls="collapseExample">
                      <i class="bi bi-bookmark-plus"></i> &nbsp; &nbsp; Bill Payment <span style={{marginLeft:'50%'}}> <b>+</b></span>
                        </li>
                        <div class="collapse mt-2" id="collapseExample3">
                            <div class="card me-3 ms-0 ">
                            
                            <Link to="/bill" className="list-group-item bg-success p-3 text-white" >
                            <i class="bi bi-credit-card-2-back"></i>  &nbsp; &nbsp;
                          
                         Mobile & Broadband
                        </Link>

                        <Link to="/bill" className="list-group-item bg-primary p-3  text-white" >
                            <i class="bi bi-tags"></i>  &nbsp; &nbsp;
                           

                              Gas Pipeline
                        </Link>  
                        <Link to="/bill" className="list-group-item bg-danger p-3  text-white" >
                            <i class="bi bi-p-circle"></i> &nbsp; &nbsp;
                         
                        LIC/Insurance 


                        </Link> 
                        <Link to="/bill" className="list-group-item bg-info p-3 text-white" >
                            <i class="bi bi-file-earmark-plus"></i> &nbsp; &nbsp;
                            Credit Card
                        </Link> 
                        <Link to="/bill" className="list-group-item bg-warning p-2 text-white" >
                            <i class="bi bi-file-earmark-plus"></i>  &nbsp; &nbsp;
                       
                                      Loan EMI
                        </Link> 
                            </div>
                        </div> <hr />
                    </nav>
                </div>
            </div>

        </>
    );
};

export default Unav;