import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Claw from "../Upages/Claw";
import { Helmet } from 'react-helmet';

const Law = () => {

    return(
        <>
         <Helmet>
        <title>Internal Repairs And Maintenance For Society</title>
        <meta name="title" property="og:title" content="Internal Repairs And Maintenance For Society" />
        <meta name="description" content="Repairs and maintenance to be carried out by Society Bye - Law 68 (a) : The following repairs and maintenance of the property of the Society" />
        <meta property="og:description" content="Repairs and maintenance to be carried out by Society Bye - Law 68 (a) : The following repairs and maintenance of the property of the Society" />
      </Helmet>
            <Navbar />
           <Claw />
            <Footer/>
    </>
    );
}
export default Law;



